<template>
	<div class="ol-wrapper ol-wrapper--large">
		<div class="ol-checkout-content">
			<div class="ol-cart">
				<div class="ol-cart-list">
					<div class="ol-dashboard__order-address">
						<div class="ol-dashboard__order-address-item">
							<h3 class="ol-dashboard__order-address-item-title">
								Alamat Pengiriman
							</h3>
							<h4
								class="ol-dashboard__order-address-item-name"
								style="text-transform: capitalize"
							>
								{{ shipping.name }}
							</h4>
							<p class="ol-dashboard__order-address-item-desc">
								{{ shipping.phone }}<br />
								{{ shipping.address }},
								{{ shipping.postal_code }},
								{{ shipping.village_name }}, Kec.&nbsp;{{
									shipping.district_name
								}},&nbsp;{{ shipping.city_name }},&nbsp;{{
									shipping.province_name
								}}
							</p>
						</div>
						<div class="ol-dashboard__order-address-item">
							<h3 class="ol-dashboard__order-address-item-title">
								Metode Pembayaran
							</h3>
							<div style="display: flex">
								<div class="order-sel-paymethod">
									<div class="order-sel-paymethod-child">
										<img
											:src="payment.icon_url"
											style="height: 20px"
											:alt="payment.name"
											:title="payment.name"
										/>
									</div>
								</div>
								<div class="about-pay"></div>
							</div>
							<h4 class="ol-dashboard__order-address-item-name">
								{{ payment.type + " (" + payment.name + ") " + tenor_txt }}
							</h4>
						</div>
					</div>

					<div v-if="loadingSummary" class="ol-dashboard__order-address" >
						<SkeletonLoader height="90px" width="100%" /><br />
						<SkeletonLoader height="90px" width="100%" /><br />
						<SkeletonLoader height="90px" width="100%" /><br />
					</div>
					<div v-else>
						<div class="ol-cart" >
							<div class="ol-cart-list" id="keranjang">
								<div class="shopping-cart">
									<div class="column-labels">
										<label class="product-check">Check</label>
										<label class="product-image">Pilih Semua</label>
										<label class="product-details">Produk</label>
										<label class="product-price">Harga</label>
										<label class="product-quantity">Jumlah</label>
										<label class="product-removal">Hapus</label>
										<label class="product-line-price">Total</label>
									</div>
									<div v-if="summary != null" class="ol-cart-list__body">
										<div v-for="item in summary.cart_items" :key="item.id" class="list-cart-product">
											<div class="product">
												<div class="product-check">
													<i class="ol-fa fas fa-check"></i>
												</div>
												<div class="product-image">
													<router-link :to="'/product/'+item.variant_detail.slug">
														<img v-if="item.variant_detail.r_uni_product_image" :src="item.variant_detail.r_uni_product_image.image_url" :alt="item.variant_detail.name_long" :title="item.variant_detail.name_long">
														<img v-else :src="noImage()" :alt="item.variant_detail.name_long" :title="item.variant_detail.name_long">
													</router-link>
												</div>
												<div class="product-details">
													<div class="product-title">
														<router-link :to="'/product/'+item.variant_detail.slug">
															<h3 class="ol-cart-list__body-item-desc-name">
																{{item.variant_detail.name_long}}
															</h3>
															<span v-if="item.r_more_transaction_cart_bundlings.length < 1" class="ol-cart-list__body-item-desc-type">
																<!-- {{item.variant_detail.variant}} -->
															</span>
															<span v-else class="ol-cart-list__body-item-desc-type">
																<span v-for="detail in item.r_more_transaction_cart_bundlings" :key="detail.id+'detail-bundle'"> {{detail.r_uni_product_variants.name_short}} <b>x</b> {{detail.quantity}}<br></span>	
															</span>
														</router-link>
													</div>
													<span v-if="item.r_uni_product_variants">
														<span v-if="item.r_uni_product_variants.r_uni_product.variant_1_name && item.r_uni_product_variants.r_uni_product.variant_2_name">
															<p class="product-description">{{item.r_uni_product_variants.r_uni_product.variant_1_name}} : {{item.variant_detail.variant}}, {{item.r_uni_product_variants.r_uni_product.variant_2_name}} : {{item.variant_detail.color}}</p>
														</span>
														<span v-else>
															<p class="product-description">Varian : {{item.variant_detail.variant}}&nbsp;</p>
														</span>
													</span>
													<span v-else>
														<p class="product-description">Varian : {{item.variant_detail.variant}}&nbsp;</p>
													</span>
													<!-- <p class="product-description">Varian : {{item.variant_detail.variant}}&nbsp;</p> -->
												</div>
												<div class="product-price">
													<span
														class="ol-home-product__content-item-list--item-price-real" v-if="item.variant_detail.r_uni_product_variant_prices.more_discount &&item.variant_detail.r_uni_product_variant_prices.more_discount != '0%'">
														{{item.variant_detail.r_uni_product_variant_prices.ho_normal_rp}}</span>
														
														<small class="ol-home-product__content-item-list--item-price-discount versi-dekstop">
															{{!item.is_flashsale ? item.variant_detail.r_uni_product_variant_prices.more_discount_price_rp : item.sub_total_rp}}
															<!-- {{item.sub_total_rp}} -->
														</small>
														<small class="ol-home-product__content-item-list--item-price-discount versi-mobile">
															<!-- {{!item.is_flashsale ? item.variant_detail.r_uni_product_variant_prices.more_discount_price_rp : item.sub_total_rp}} -->
															{{item.sub_total_rp}}
														</small>
												</div>
												<div class="product-quantity">
													{{ item.quantity }} Barang
												</div>
												<div class="product-removal">
													&nbsp;
												</div>
												<div v-if="loadingSummary" class="product-line-price ol-home-product__content-item-list--item-price-discount"><SkeletonLoader style="height:20px;width:100px;float:right;" corner="5" /></div>
												<div v-else class="product-line-price ol-home-product__content-item-list--item-price-discount">
													{{item.sub_total_rp}}
												</div>	
											</div>
											<div v-if="item.variant_detail.pre_checkout_status == false" class="ol-alert-static ol-alert--danger" id="wrongStok" style="display: block;margin-top:8px;">
												<div class="row no-gutters">
													<div class="col-lg-9 col-md-12 info-hubungi-admin">
														{{item.variant_detail.pre_checkout_message}}
													</div>
													<div class="col-lg-3 col-md-12 btn-hubungi-admin" >
														<div v-if="item.variant_detail.pre_checkout_message.includes('sedang habis')" >
															<a :href="'/product/'+item.variant_detail.slug+'#rekomendasi'" class="ol-btn" style="padding:4px 15px;">
																Pilih Produk Serupa
															</a>
														</div>
														<div v-else>
															<a @click="chatDelivery(item.variant_detail.name_long,shipping.city_name,shipping.province_name)" target="_blank" class="ol-btn" style="padding:4px 15px;">Hubungi Admin</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="ol-cart-list__footer">
							<div class="ol-cart-list__footer-item">
								<p><strong>Catatan : {{ this.note }}</strong></p>
							</div>
							<div class="ol-cart-list__footer-item">
								<dl class="ol-cart-list__footer-item-total">
									<dt>Total Harga</dt>
									<dd>{{this.summary.summary.cart_grand_total_rp}}</dd>
									<dt>Total Ongkos Kirim</dt>
									<dd>{{this.summary.summary.shipping_price_rp}}</dd>
									<!-- // TODO: uncomment tampilan biaya layanan -->
									<!-- <dt v-if="fee">Biaya Layanan</dt>
									<dd v-if="fee">
										{{ fee_rp }}
									</dd> -->
									<dt class="font-16"><strong>Total</strong></dt>
									<dd class="font-16"><strong>{{this.summary.summary.grand_total_rp}}</strong></dd>
									<dt v-if="appliedVoucher" class="total-voucher lbl-voucher">Potongan Voucher #{{voucher ? voucher.code : ""}}</dt>
									<dd v-if="appliedVoucher" class="total-voucher potongan-voucher" > -{{ voucher ? diskon : "" }}</dd>
									<dt v-if="appliedVoucher" class="total-voucher font-16" > <strong>Total Pembayaran</strong> </dt>
									<dd v-if="appliedVoucher" class="total-voucher font-16" > <strong class="info-grand-total">{{ this.summary.summary.bill_total_rp }}</strong> </dd>
									<div v-if="cekProdukPOLKI == 0" class="ol-popup-box__voucher-input voucher-input-checkout" >
										<div class="add-fix">
											<form v-on:submit.prevent="">
												<input v-if="appliedVoucher" id="couponvalue" style="text-transform: uppercase;" type="text" name="" class="ol-input" placeholder="Masukkan Kode Voucher" v-model="input.kode"/>
												<button v-show="!loadingVoucher && !appliedVoucher" type="button" class="ol-btn-coupon" @click="showPopupVoucher()">
													Gunakan Voucher
												</button>
												<button v-show="!loadingVoucher && appliedVoucher" type="button" class="ol-btn-coupon" @click="hapusVoucher()">
													<i class="fas fa-times ol-fa"></i>
												</button>
												<button v-show="loadingVoucher" type="button" class="ol-btn-coupon" style="pointer-events: none">
													<i class="fas fa-spinner fa-pulse ol-fa"></i>&nbsp;Terapkan
												</button>
											</form>
										</div>
										<div class="promoalert" id="infopesan" style="text-align: left">
											<p class="info-validation">
												{{ alertVoucher.pesan }}
											</p>
										</div>
									</div>
								</dl>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- <p class="ol-cart-list--stock-notif">
			Cek kembali pesanan yang akan di checkout
		</p> -->
		<div class="ol-checkout-nav">
			<router-link
				href="javascript:void(0)"
				to="/checkout/payment"
				class="ol-btn ol-btn--secondary"
				><i class="ol-fa fas fa-arrow-left"></i>&emsp;Kembali
			</router-link>

			<button
				v-if="loadingBayar"
				type="button"
				class="ol-btn"
				style="pointer-events: none"
			>
				Bayar &emsp;<i class="fas fa-spinner fa-pulse ol-fa"></i>
			</button>

			<a
				href="javascript:void(0)"
				v-if="
					summary &&
					summary.allProductsIsValid &&
					!loadingSummary &&
					!loadingBayar
				"
				type="button"
				@click="saveTr()"
				:class="loadingSummary ? 'ol-btn ol-btn-disable' : 'ol-btn'"
			>
				Bayar&emsp;
				<i class="ol-fa fas fa-arrow-right"></i>
			</a>

			<router-link
				v-if="!summary || !summary.allProductsIsValid"
				href="javascript:void(0)"
				to="/cart"
				:class="loadingSummary ? 'ol-btn ol-btn-disable' : 'ol-btn'"
			>
				Ubah Keranjang Belanja &emsp;<i
					class="ol-fa fas fa-arrow-right"
				></i>
			</router-link>
		</div>
		<section class="ol-checkout-couponcarousel">
			<div class="ol-wrapper ol-wrapper--large">
				<div
					id="myCoupon"
					class="modal-coupon"
					style="display: block"
					v-show="modal.showing"
				>
					<!-- Modal content -->
					<div class="modal-coupon-content" v-if="modal.data != null">
						<span
							class="close-coupon"
							v-on:click="
								() => {
									this.modal.showing = false;
								}
							"
							>&times;</span
						>
						<div class="row">
							<div class="">
								<div class="imgCoupon">
									<img
										:srcset="modal.data.image"
										class="voucher-image"
										style="width: 100%"
									/>
								</div>

								<div class="coupontitle">
									<h1>
										Voucher
										<span class="voucher">{{
											renderPotongan(modal.data)
										}}</span>
									</h1>
									<h2 class="voucher-code">
										{{ modal.data.code }}
									</h2>
									<div style="display: flex">
										<div
											style="
												display: flex;
												align-items: center;
											"
										>
											<i
												class="fas fa-clock fa-sm"
												style="
													margin-right: 10px;
													color: #364b7b;
												"
											></i>
										</div>

										<p>Berlaku Hingga</p>
										<strong class="voucher-expired">
											{{ modal.data.expired }}</strong
										>
									</div>
								</div>

								<div class="snk">
									<h2>Syarat dan Ketentuan</h2>
									<div
										class="voucher-snk"
										v-html="modal.data.term_condition"
									>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <h2>Voucher Belanja</h2>
				<ul class="card-vouchers">
                    <li class="card-voucher" v-for="voucher in data" :key="voucher.id">
                        <div class="main">
                            <div class="content-voucher">
                                <h2><img :src="assetIcon('discount-red.png')" width="15"> Potongan Harga</h2>
                                <h1>{{ voucher.voucher_rp }}</h1>
                                
                                <p>Min. transaksi {{ voucher.min_purchase_rp }}</p>
                                <p>Hingga {{ voucher.expired }}</p>
                            </div>
                        </div>
                        <div class="copy-button">
                            <input type="text" readonly :value="voucher.code" />
                            <button v-on:click="gunakan(voucher)" class="copybtn">Gunakan</button>
                        </div>
                    </li>
                </ul> -->
				<BannerBottomLoading v-if="loading" />
			</div>
		</section>
		<Popup title="Gunakan Voucher" id="popupVoucher">
			<div class="ol-popup-box__voucher-input voucher-input-checkout" style="margin-bottom:30px;margin-top:-20px;">
				<div class="add-fix">
					<form v-on:submit.prevent="">
						<input id="couponvalue" style="flex: inherit;margin-left:10px;" type="text" name="" class="ol-input" placeholder="Masukkan Kode Voucher" v-model="input.kode"/>
						<button
							v-show="!loadingVoucher &&!appliedVoucher"  style="margin-right:10px;" type="button" class="ol-btn-coupon" @click="terapkan()">
							Gunakan
						</button>
					</form>
				</div>
			</div>
			<div class="row" style="justify-content: center;">
				<ul class="popup-card-vouchers">
					<li class="popup-card-voucher" v-for="voucher in data" :key="voucher.id">
                        <div class="main">
                            <div class="content-voucher">
								<h2><img :src="assetIcon('discount-red.png')" width="15"> 
                                    Diskon &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span v-if="voucher.show_on ===1">{{ voucher.voucher_rp }}</span>
                                    <span v-else-if="voucher.show_on === 3">{{ voucher.max_discount_price_rp }}</span>
                                    <span v-else-if="voucher.show_on === 4">{{ voucher.percentage }}%</span>
                                </h2>
                                <div class="break-1"></div>
								<p v-if="voucher.min_purchase_rp != 'Rp0'">Min. Transaksi {{ voucher.min_purchase_rp }}</p>
                				<p v-else>Tanpa Min. Transaksi</p>
                                <p>Hingga {{ voucher.expired }}</p>
                            </div>
                        </div>
                        <div class="copy-button">
                            <button v-on:click="gunakan(voucher)" class="copybtn">Gunakan</button>
                        </div>
                    </li>
                </ul>
			</div>
        </Popup>
		<div v-for="data in cekProdukPOLKI"></div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import globalUrl from "@/util/globallUrl";
import helpme from "@/util/helpme";
import BannerBottomLoading from "@/components/loading/BannerBottomLoading";
import SkeletonLoader from "@/components/loading/skeletonLoader";
import Popup from '@/components/popup/Popup.vue'
export default {
	name: "CheckoutReview",
	components: {
		BannerBottomLoading,
		SkeletonLoader,
		Popup,
	},
	data() {
		return {
			loading: false,
			loadingSummary: false,
			loadingBayar: false,
			loadingVoucher: false,
			appliedVoucher: false,
			wa_no: "",
      		wa_link: "",
			alertVoucher: {
				showing: false,
				pesan: "",
				type: "",
			},
			data: [],
			modal: {
				showing: false,
				data: null,
			},
			input: {
				kode: "",
				data: null,
			},
			queue: null,
		};
	},
	computed: {
		...mapGetters("auth", ["authenticated"]),
		...mapGetters("home", ["metaPages", "waText"]),
		...mapGetters("transaction/cart", [
			"variants",
			"grandtotal",
			"voucher",
			"grandtotalVoucher",
			"diskon",
			"note",
		]),
		...mapGetters("transaction/checkout", [
			"shipping",
			"payment",
			"summary",
			"order",
			"dropship",
			"tenor",
			"fee",
		]),
		...mapGetters("product/detail", ["recommended", "getUtmContent"]),
		fee_rp: function() {
			return helpme.toRp(this.fee)
		},
		tenor_txt: function() {
			if(this.tenor > 0) return this.tenor + " bulan"
			return "";
		},
		cekProdukPOLKI() {
            var haloo =  _.filter(this.summary.cart_items, {uni_product_variants_id: 1608})
			return haloo.length;
        },
	},
	methods: {
		...mapActions("home", ["fetchMetaPages", "getSetting"]),
		...mapActions("transaction/cart", ["set_voucher"]),
		...mapActions("transaction/checkout", [
			"fetchSummary",
			"saveTransaction",
			"getIpPublic"
		]),
		...mapActions("product/detail", ["removeUtmContent"]),
		detailVoucher(data) {
			this.modal.data = data;
			this.modal.showing = true;
		},
		async chatDelivery(product, city, province){
			let texts = "Hai MORE saya ingin bertanya *Produk : "+product+"*, Ongkos kirim ke : *"+city+" Provinsi "+province+"*";
            let sendWa ={
                phone:process.env.VUE_APP_WA_CS,
                text:texts
            }
            window.open('https://api.whatsapp.com/send?'+$.param(sendWa), '_blank');
        },
		async _getSetting(){
		let setting = await this.getSetting();
			let wa = _.find(setting, {id:8});
			if(wa) this.wa_no =wa.content;
			if(wa) this.wa_link = wa.description;
		},
		async gunakan(data) {
			if(data) {
				this.input.kode = data.code;
				await this.terapkan();
				this.showPopupVoucher(false);
			}
		},
		async terapkan() {
			if (this.input.kode) {
				this.showPopupVoucher(false);
				this.appliedVoucher = false;
				$("#couponvalue").prop("disabled", false);
				$("#infopesan").hide();

				this.loadingVoucher = true;
				let result = await this.set_voucher(this.input.kode);
				this.loadingVoucher = false;
				if (result.success) {
					this.alertVoucher.pesan = "Voucher berhasil diterapkan.";
					this.appliedVoucher = true;
					// $("#couponvalue").prop("disabled", true);

					$("#infopesan")
						.removeClass("promoalertfail")
						.slideDown("fast");

					this.$store.commit(
						"transaction/checkout/setSummaryWithDiscount",
						this.voucher.potongan
					);
				} else {
					this.alertVoucher.showing = true;
					this.alertVoucher.pesan = result.data;
					$("#infopesan").addClass("promoalertfail").slideDown("fast");
				}
			}

			if (!this.input.kode) {
				$("#infopesan").slideUp("fast");
			}
		},
		hapusVoucher() {
			this.$store.commit("transaction/cart/setVoucher", null);
			this.appliedVoucher = false;
			this.input.kode = null;
			$("#couponvalue").prop("disabled", false);
			var y = document.getElementById("infopesan");
			y.style.display = "none";
		},
		renderPotongan(voucher) {
			if (voucher.type_discount == 1) {
				return helpme.toRp(voucher.voucher);
			} else if (voucher.type_discount == 2) {
				return "Diskon " + voucher.voucher + "%";
			}
		},
		jq() {
			$(".ol-checkout-couponcarousel-list").slick({
				slidesToScroll: 1,
				slidesToShow: 3,
				dots: !1,
				autoplay: !0,
				autoplaySpeed: 5e3,
				arrows: false,
				pauseOnFocus: !1,
				infinite: !0,
				speed: 500,
				fade: !1,
				responsive: [
					{
						breakpoint: 891,
						settings: {
							slidesToShow: 2,
						},
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 1,
						},
					},
				],
			});
		},
		async getData() {
			this.loading = true;
			await globalUrl.WS_TRANSACTION.CART.GET_VOUCHER()
				.then((response) => {
					this.data = response.data.data;
					this.loading = false;
				})
				.catch((error) => {
					// console.log(error.data)
					this.loading = false;
				});
			this.jq();
		},
		async getSummary() {
			this.loadingSummary = true;
			// console.log(this.shipping);
			if(this.shipping == null){
				location.href = "/checkout";
			}
			let data = { city_id: this.shipping.city };
			if (this.input.kode) {
				data.address_id = this.shipping.id;
				data.voucher = this.input.kode;
				data.payment_code = this.payment.code;
			}
			// TODO: biaya layanan
			// data.fee = this.fee;
			await this.fetchSummary(data);
			this.loadingSummary = false;
		},
		async saveTr() {
			if (!this.order || this.order == null) {
				$("#infopesan").slideUp("fast");

				let affiliate = null;
				if(this.getUtmContent.length > 0) {
					let ip = await this.getIpPublic();
					affiliate = {
						"utm_content": this.getUtmContent,
						"ip_device": ip,
						"name_device": this.$browserDetect.meta.ua
					};
				}

				let data = {
					voucher: this.input.kode,
					city_id: this.shipping.city,
					address_id: this.shipping.id,
					payment_code: this.payment.code,
					note: this.note,
					dropshipper_id: this.dropship ? this.dropship.id : null,
					dropshipper_name: this.dropship ? this.dropship.name : null,
					dropshipper_phone: this.dropship ? this.dropship.phone : null,
					// TODO: biaya layanan
					// fee: this.fee,
					tenor: this.tenor,
					affiliate
				};

				let parsedItems = _.map(this.variants, (q) => {
					return {
						id: q.r_uni_product.id,
						name: q.r_uni_product.name,
						brand: q.r_uni_product.brand,
						variant: q.name_long,
						quantity: q.quantity,
						price: q.subtotal,
					};
				});
				this.$gtag.event('purchase', { 'total':this.summary.summary.bill_total,'products': parsedItems });

				// this.analytics("InitiateCheckout", {
				// 	content_type: "product",
				// 	value: this.summary.summary.bill_total,
				// 	num_items: _.sumBy(this.summary.cart_items, "quantity"),
				// 	currency: "IDR",
				// });
				fbq('track', 'InitiateCheckout', {
					content_category:"Checkout",
					// content_ids: "",
					// contents: "",
					currency:"IDR",
					num_items:_.sumBy(this.summary.cart_items, "quantity"),
					value:this.summary.summary.bill_total
				});


				this.loadingBayar = true;
				let result = await this.saveTransaction(data);
				if (result.statusCode == 500)
					this.$toast.open({
						message: "Gagal menyimpan transaksi!",
						type: "error",
						duration: 5000,
					});

				if (result.success) {
					// this.removeUtmContent(); //Hapus UTM Content ketika Checkout
					this.$store.commit("transaction/cart/setNullPostOrder");
					this.$store.commit(
						"transaction/checkout/setDropship",
						null
					);
					if (result.data.faspay_response.redirect_url) {
						window.location.href =
							result.data.faspay_response.redirect_url;
					} else {
						this.loadingBayar = false;
						this.$router.push("/checkout/success");
					}
				} else {
					this.loadingBayar = false;
					if (result.data.voucher.message) {
						// this.input.kode = result.data.voucher.request_code;
						this.alertVoucher.pesan = result.data.voucher.message;
						$("#infopesan")
							.addClass("promoalertfail")
							.slideDown("fast");
					}
					$("html, body").animate(
						{
							scrollTop: $("div.order-sel-paymethod").offset()
								.top,
						},
						500
					);
				}
			} else {
				this.$router.push("/profile/orders");
			}
		},
		showPopupVoucher(show = true) {
			(show) ? Popup.methods.show("popupVoucher") : Popup.methods.hide("popupVoucher");
		}
	},
	watch: {
		"input.kode": function () {
			$("#infopesan").slideUp("fast");
		},
	},
	async mounted() {
		// console.log(this.$browserDetect.meta.ua);
		if (this.order != null) {
			// if(this.order.faspay_response.redirect_url){
			//     this.$router.push('/profile/orders');
			// }else{
			this.$router.push("/checkout/success");
			// }
		} else {
			await this.getSummary();
			await this.getData();
			
			let parsedItems = _.map(this.variants, (q) => {
				return {
					id: q.r_uni_product.id,
					name: q.r_uni_product.name,
					brand: q.r_uni_product.brand,
					variant: q.name_long,
					quantity: q.quantity,
					price: q.subtotal,
				};
			});
			this.$gtag.event('checkout', { 'total':this.summary.summary.bill_total,'products': parsedItems });
			
		}
		this._getSetting();
		// this.analytics("AddPaymentInfo");
		// if(this.voucher.code){
		//     this.input.kode = this.voucher.code;
		//     this.terapkan();
		// }
	},
};
</script>

<style scoped lang="css">
@import "../../../assets/css/coupon.css";
@import "../../../assets/css/cart.css";
.ol-btn-disable {
	background-color: #aaa;
	border-color: #aaa;
	cursor: default;
	pointer-events: none;
}
.popup-card-vouchers {
	width: 100%;
	margin-top: -20px;
}
.popup-card-voucher {
	display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    padding: 20px;
    background: var(--white);
    border-radius: 8px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 15%);
    scroll-snap-align: start;
    transition: all 0.2s;
    margin: 0 15px 15px 15px;
}

.product {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px solid #eee;
  }

  .ol-alert-static{
	margin-bottom: 0px;
  }

  .btn-hubungi-admin{
	text-align:right;
  }
  .info-hubungi-admin{
	font-size:12px;
  }
  @media (max-width: 768px) {
	.info-hubungi-admin{
		font-size:10px;
	}
	.btn-hubungi-admin{
		text-align:center;
		margin-top:2px;
	}

	.ol-alert-static{
		margin-top:4px !important;
	}
  }
</style>